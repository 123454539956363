import { Controller } from "@hotwired/stimulus"

export default class Accordion extends Controller {
  static targets = ["item", "itemTitle", "itemContent", "itemCollapsedIcon", "itemExpandedIcon"]

  connect() {

  }

  toggleCollapse(event) {
    const item = event.currentTarget.closest("[data-tw--accordion-target='item']");
    const position = this.itemTargets.indexOf(item);
    const contentElem = this.itemContentTargets[position];
    const iconCollapsed = this.itemCollapsedIconTargets[position];
    const iconExpanded = this.itemExpandedIconTargets[position];

    if (contentElem.classList.contains("hidden")) {
      contentElem.classList.remove("hidden");
      iconCollapsed.classList.add("hidden");
      iconExpanded.classList.remove("hidden");
    }
    else {
      contentElem.classList.add("hidden");
      iconCollapsed.classList.remove("hidden");
      iconExpanded.classList.add("hidden");
    }
  }
}
